.custom-anchor-link.fixed .ant-anchor.ant-anchor-fixed::before{
    display: none;
}
.ant-anchor-wrapper.fixed .ant-anchor-ink {
    display: none;
}

.ant-anchor-wrapper.fixed .ant-anchor-link {
    padding-left: 0px;
}

.ant-anchor-wrapper.fixed .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 13px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
}

.ant-anchor-wrapper.fixed .ant-anchor-link a:hover {
    background-color: #eee;
}

.ant-anchor-wrapper.fixed .ant-anchor-link .ant-anchor-link-title-active {
    background-color: #eee;
}
.ant-anchor-link-menu {
    display: flex;
    align-items: center;
}

.ant-anchor-link-menu svg {
    margin-right: 8px;
}

.ant-anchor-link-menu h4 {
    margin: 0px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
}
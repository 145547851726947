.threads {
    /* box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5); /* Shadow on the right side */
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto;
    padding-right: 5px;
}
.threads ant-flex:after{
    /* Add shadow to the end of item*/
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    background-color: #2f54eb;
    border-radius: 50%;
}

.thread-item:hover {
    background-color: #f5f5f5;
    cursor: pointer;
    border-radius: 0.5rem;
}

.thread-item {
    display: flex;
    width: 100%;
    overflow: hidden; /* Ensures the content doesn't overflow */
    white-space: nowrap; /* Keeps the text on one line */
    text-overflow: ellipsis; /* Adds an ellipsis if the text overflows */
    /* Additional styling */
}


.thread-item.active {
    background-color: #1b73f5;
    border-radius: 0.5rem;
}
.thread-item.active button {
    color: #ffffff!important;
}

.user-device-nav-bg {
    height: 150px;
    margin-top: 24px;
    border-radius: 12px;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
    background-size: cover;
    background-position: 50%;
}

.card-user-device-head .ant-card-head {
    border-bottom: 0;
    padding: 0 16px;
}

.card-user-device-head {
    margin: -53px 0 4px;
    padding-top: 12px;
    padding-bottom: 16px;
}

.card-user-device-head .ant-card-head .ant-avatar {
    box-shadow: none;
    border-radius: 8px;
    margin: 0;
}

.card-user-device-head .ant-card-head .avatar-info {
    margin-left: 16px;
}

.ant-card-head-title p {
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 0;
}

.card-user-device-head .ant-card-head .avatar-info h4 {
    font-size: 24px;
}

.card-user-device-head .ant-radio-group .ant-radio-button-wrapper {
    height: 34px;
    font-weight: 700;
    line-height: 34px;
}

.ant-radio-button-wrapper {
    color: #595959;
}

@media (min-width: 768px) {
    .user-device-nav-bg {
        /*margin-top: -87.8px;*/
    }

    .card-user-device-head {
        margin: -53px 24px 16px;
    }
}
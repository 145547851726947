.dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: black;
    margin: 0 2px;
    animation: dotFlashing 1.0s infinite linear alternate;
    animation-fill-mode: both;
}

@keyframes dotFlashing {
    0% {
        background-color: black;
    }
    50%, 100% {
        background-color: transparent;
    }
}

/* AppLayout.css */
.logo {
    float: left;
    height: 64px;
    line-height: 64px;
    vertical-align: top;
    margin-right: 16px;
}
.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.logo img {
    height: 24px;
    /*vertical-align: middle;*/
}


.messageContainer {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    max-height: 400px !important; /* adjust this value based on your requirements */
    min-height: 500px;
    padding: 0 12px
}

.chatContainer {
    padding: 0 12px 12px;
    border-radius: 8px;
    /*background-image: url('images/ai-chat.png');*/
    background-size: contain; /* Cover the entire container */
    background-repeat: repeat-x; /* Prevent repeating the image */
    /*filter: blur(2px);*/
}

.suggestionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.suggestionsContainer .suggestionItem {
    margin: 7px 0
}

.articlesContainer {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px 0;
}

.articleItem {
    flex: 0 0 auto;
    margin-right: 20px; /* Adjust the space between cards */
}

.audio{
    max-width: 400px;
    height: 40px;
}
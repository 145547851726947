.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.d-flex {
    display: flex;
}
.d-block {
    display: block;
}

.float-right{
    float: right;
}
.float-left{
    float: left;
}
.float-top{
    float: top;
}
.align-items-center {
    align-items: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-end {
    justify-content: end;
}

.space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.space-align-block {
    flex: none;
    margin: 8px 4px;
    padding: 4px;
    /*border: 1px solid #40a9ff;*/
}

.space-align-block .mock-block {
    display: inline-block;
    padding: 32px 8px 16px;
    background: rgba(150, 150, 150, 0.2);
}

.dashed-border {
    border-radius: 7px;
    border: 1px dashed #ccc;
    padding: 10px;
    margin: 10px 0;
}

.container-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-center-x {
    display: flex;
    justify-content: center;
}

.container-center-y {
    display: flex;
    align-items: center;
}


.m-0 {
    margin: 0;
}

/* Top Margin */
.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: 8px;
}

.mt-2 {
    margin-top: 16px;
}

.mt-3 {
    margin-top: 24px;
}

.mt-4 {
    margin-top: 32px;
}

.mt-5 {
    margin-top: 40px;
}

.mt-6 {
    margin-top: 48px;
}

/* Right Margin */
.mr-0 {
    margin-right: 0;
}

.mr-1 {
    margin-right: 8px;
}

.mr-2 {
    margin-right: 16px;
}

.mr-3 {
    margin-right: 24px;
}

.mr-4 {
    margin-right: 32px;
}

/* Bottom Margin */
.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 8px;
}

.mb-2 {
    margin-bottom: 16px;
}

.mb-3 {
    margin-bottom: 24px;
}

.mb-4 {
    margin-bottom: 32px;
}

.mb-5 {
    margin-bottom: 40px;
}

.mb-6 {
    margin-bottom: 48px;
}

.mb-7 {
    margin-bottom: 56px;
}

/* Left Margin */
.ml-0 {
    margin-left: 0;
}

.ml-1 {
    margin-left: 8px;
}

.ml-2 {
    margin-left: 16px;
}

.ml-3 {
    margin-left: 24px;
}

.ml-4 {
    margin-left: 32px;
}


.p-0 {
    padding: 0;
}

.p-1 {
    padding: 8px;
}

.p-2 {
    padding: 16px;
}

.p-3 {
    padding: 24px;
}

.p-4 {
    padding: 32px;
}

.p-5 {
    padding: 40px;
}

.p-6 {
    padding: 48px;
}

.pt-0 {
    padding-top: 0;
}

.pt-1 {
    padding-top: 8px;
}

.pt-2 {
    padding-top: 16px;
}

.pt-3 {
    padding-top: 24px;
}

.pt-4 {
    padding-top: 32px;
}

.pt-5 {
    padding-top: 40px;
}

.pt-6 {
    padding-top: 48px;
}

/* Right Margin */
.pr-0 {
    padding-right: 0;
}

.pr-1 {
    padding-right: 8px;
}

.pr-2 {
    padding-right: 16px;
}

.pr-3 {
    padding-right: 24px;
}

.pr-4 {
    padding-right: 32px;
}

/* Bottom Margin */
.pb-0 {
    padding-bottom: 0;
}

.pb-1 {
    padding-bottom: 8px;
}

.pb-2 {
    padding-bottom: 16px;
}

.pb-3 {
    padding-bottom: 24px;
}

.pb-4 {
    padding-bottom: 32px;
}

/* Left Margin */
.pl-0 {
    padding-left: 0;
}

.pl-1 {
    padding-left: 8px;
}

.pl-2 {
    padding-left: 16px;
}

.pl-3 {
    padding-left: 24px;
}

.pl-4 {
    padding-left: 32px;
}



.home-background-container {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.home-background {
    background-size: contain;
    background-position-y: center;
    background-position-x: right;
    background-repeat: no-repeat;
    margin-top: 50px;
    width: 100%;
    height: 90%;
    position: absolute;
    animation: floatingAnimation 5s ease-in-out infinite;
}

@keyframes floatingAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}
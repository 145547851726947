.phone-container {
    position: relative;
    width: 300px;
    height: 600px;
}

.phone-svg {
    width: 100%;
    height: 100%;
}

.phone-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    padding: 72px 18px 57px 16px;
    border-bottom-left-radius: 31px;
    border-bottom-right-radius: 31px;
}


.phone-footer {
    position: absolute;
    bottom: 10%;
    left: 10%;
    width: 80%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
